export const urlArray = {
  tr: [
    { val: "Ana Sayfa", url: "/" },
    { val: "Ölü Piksel Testi", url: "/olu-piksel-testi" },
    { val: "Ölü Piksel Tamiri", url: "/olu-piksel-tamiri" },
    { val: "Sıkışmış Piksel Tamiri", url: "/sikismis-piksel-tamiri" },
  ],
  en: [
    { val: "Home", url: "/home" },
    { val: "Dead Pixel Test", url: "/dead-pixel-test" },
    { val: "Dead Pixel Fix", url: "/dead-pixel-fix" },
    { val: "Stuck Pixel Fix", url: "/stuck-pixel-fix" },
  ],
  de: [
    { val: "Pixeltest", url: "/pixeltest" },
    { val: "Dead Pixel Test", url: "/dead-pixel-test-de" },
    { val: "Dead Pixel Fix", url: "/dead-pixel-fix-de" },
    { val: "Stuck Pixel Fix", url: "/stuck-pixel-fix-de" },
  ],
  fr: [
    { val: "Test de Pixel", url: "/test-de-pixel" },
    { val: "Test de Pixels Morts", url: "/test-de-pixels-morts" },
    { val: "Correction des pixels morts", url: "/correction-des-pixels-morts" },
    {
      val: "Correction des pixels bloqués",
      url: "/correction-des-pixels-bloques",
    },
  ],
  es: [
    { val: "Prueba de Píxeles", url: "/prueba-de-pixeles" },
    { val: "Prueba de Píxeles Muertos", url: "/prueba-de-pixeles-muertos" },
    {
      val: "Corrección de Píxeles Muertos",
      url: "/correccion-de-pixeles-muertos",
    },
    {
      val: "Corrección de Píxeles Atascados",
      url: "/correccion-de-pixeles-atascados",
    },
  ],
  it: [
    { val: "Controlla Pixel", url: "/controlla-pixel" },
    { val: "Controlla Dead Pixel", url: "/controlla-dead-pixel" },
    { val: "Riparazione Pixel Morti", url: "/riparazione-pixel-morti" },
    { val: "Riparazione Pixel Bloccati", url: "/riparazione-pixel-bloccati" },
  ],
  pt: [
    { val: "Verificar Pixel", url: "/verificar-pixel" },
    { val: "Verifique o pixel morto", url: "/verifique-o-pixel-morto" },
    { val: "Dead Pixel Repair", url: "/dead-pixel-repair" },
    { val: "Correção de pixel travado", url: "/correcao-de-pixel-travado" },
  ],
  
}
export const languages = {
  tr: [
    { val: "İngilizce", langKey: "en" },
    { val: "Fransızca", langKey: "fr" },
    { val: "Almanca", langKey: "de" },
    { val: "İspanyolca", langKey: "es" },
    { val: "İtalyanca", langKey: "it" },
    { val: "Portekizce", langKey: "pt" },
  ],
  en: [
    { val: "Turkish", langKey: "tr" },
    { val: "French", langKey: "fr" },
    { val: "German", langKey: "de" },
    { val: "Spanish", langKey: "es" },
    { val: "Italian", langKey: "it" },
    { val: "Portuguese", langKey: "pt" },
  ],
  fr: [
    { val: "Le Turc", langKey: "tr" },
    { val: "l' Anglais", langKey: "en" },
    { val: "Allemand", langKey: "de" },
    { val: "Espanol", langKey: "es" },
    { val: "Italien", langKey: "it" },
    { val: "Portugais", langKey: "pt" },
  ],
  de: [
    { val: "Türkisch", langKey: "tr" },
    { val: "Englisch", langKey: "en" },
    { val: "Französisch", langKey: "fr" },
    { val: "Spanisch", langKey: "es" },
    { val: "Italienisch", langKey: "it" },
    { val: "Portugiesisch", langKey: "pt" },
  ],
  es: [
    { val: "Turco", langKey: "tr" },
    { val: "El inglés", langKey: "en" },
    { val: "Francés", langKey: "fr" },
    { val: "Alemán", langKey: "de" },
    { val: "Italiano", langKey: "it" },
    { val: "Portugués", langKey: "pt" },
  ],
  it: [
    { val: "Turco", langKey: "tr" },
    { val: "Francese", langKey: "fr" },
    { val: "German", langKey: "de" },
    { val: "Tedesco", langKey: "es" },
    { val: "Inglese", langKey: "en" },
    { val: "Portoghese", langKey: "pt" },
  ],
  pt: [
    { val: "Turco", langKey: "tr" },
    { val: "Francês", langKey: "fr" },
    { val: "Alemão", langKey: "de" },
    { val: "Espanhol", langKey: "es" },
    { val: "Italiano", langKey: "it" },
    { val: "Inglês", langKey: "en" },
  ],
}
