import React, { createContext, useReducer } from "react"
import Reducer from "./Reducer"
import { urlArray, languages } from "./storeData"
const selectedLang =
  typeof window !== "undefined"
    ? localStorage.getItem("selectedLanguage")
      ? localStorage.getItem("selectedLanguage")
      : "tr"
    : "tr"

const navLinks = urlArray[selectedLang]
const initialState = {
  selectedLanguage: selectedLang,
  languages: languages[selectedLang].sort((a, b) =>
    a.val > b.val ? 1 : b.val > a.val ? -1 : 0
  ),
  navLinks,
  urlArray,
  langArray: languages,
}
export const storeContext = createContext(initialState)
const StoreContext = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)
  return (
    <storeContext.Provider value={[state, dispatch]}>
      {children}
    </storeContext.Provider>
  )
}

export default StoreContext
