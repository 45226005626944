import React from "react"
import { Link } from "gatsby"
import { Transition } from "@headlessui/react"
import Logo from "../contents/Logo"

import LangButton from "../contents/Buttons/LangButton"
import MenuButton from "../contents/Buttons/MenuButton"
import useStore from "../store/useStore"
import useDocumentScrollThrottled from "../lib/useDocumentScrollThrottled"

const Navbar = () => {
  const isScrolledTop = useDocumentScrollThrottled()

  const { navLinks } = useStore()
  return (
    <Transition
      show={isScrolledTop}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <nav
        className={`shadow-md py-1 px-3 bg-white md:py-3 transition duration-150 ease-in-out fixed top-0 left-0 w-full`}
      >
        <div className="container mx-auto flex justify-between">
          <Link to={navLinks[0].url}>
            <Logo className="h-6 md:h-8" />
          </Link>

          <div className="inline-block">
            <LangButton />
            <MenuButton />
          </div>
        </div>
      </nav>
    </Transition>
  )
}

export default Navbar
