import React, { memo } from "react"
import { Menu, Transition } from "@headlessui/react"

import useStore from "../../store/useStore"
import SvgLangIcon from "../Icons/SvgLangIcon"
const LangButton = () => {
  const { languages, changeLanguage } = useStore()

  const clickChangeLanguage = lVal => {
    changeLanguage(lVal)
  }
  return (
    <div className="inline-block">
      <Menu>
        {({ open }) => (
          <>
            <span>
              <Menu.Button
                type="button"
                className="bg-green-500 focus:bg-green-600 hover:bg-green-600 text-white  px-2 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-colors duration-150"
              >
                <SvgLangIcon
                  className="fill-current max-h-full max-w-full"
                  height={18}
                  width={18}
                />
              </Menu.Button>
            </span>
            <Transition
              show={open}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
              >
                {languages.map(lang => (
                  <Menu.Item
                    key={lang.langKey}
                    as="button"
                    onClick={() => clickChangeLanguage(lang.langKey)}
                    className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left hover:bg-gray-100  ease-linear transition-colors duration-150 outline-none focus:outline-none"
                  >
                    {lang.val}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

export default memo(LangButton)
