import React, { memo } from "react"
import { Link } from "gatsby"
import Logo from "../contents/Logo"
const Footer = () => {
  return (
    <footer className="pt-2 pb-4 border border-gray-200">
      <div className="flex items-center justify-between px-3 py-2 mx-auto prose-sm prose sm:prose lg:prose-lg xl:prose-xl md:px-0">
        <Logo className="h-4 md:h-5" />
        <div>
          <Link
            to="/privacy-policy"
            style={{ textDecoration: "none" }}
            className="px-3 py-1 mr-3 text-xs text-white bg-green-500 rounded-md focus:bg-green-600 hover:bg-green-600 md:text-sm focus:outline-none hover:shadow-lg"
          >
            <span className="font-bold text-white">Privacy Policy</span>
          </Link>
          <Link
            to="/term-of-usage"
            style={{ textDecoration: "none" }}
            className="px-3 py-1 text-xs text-white bg-purple-600 rounded-md focus:bg-purple-700 hover:bg-purple-700 md:text-sm focus:outline-none hover:shadow-lg"
          >
            <span className="font-bold text-white">Term of Use</span>
          </Link>
        </div>
      </div>
    </footer>
  )
}
export default memo(Footer)
