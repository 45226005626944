import { useEffect, useState } from "react"
import { throttle } from "lodash"
let lastScrollTop = 0
const useDocumentScrollThrottled = () => {
  const [isTop, setIsTop] = useState(true)
  const handleDocumentScroll = event => {
    const st = window.pageYOffset || document.documentElement.scrollTop

    if (st > lastScrollTop) {
      setIsTop(false)
    } else {
      setIsTop(true)
    }
    lastScrollTop = st <= 0 ? 0 : st
  }
  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250)
  useEffect(() => {
    window.addEventListener("scroll", handleDocumentScrollThrottled)
    return () => {
      window.removeEventListener("scroll", handleDocumentScrollThrottled)
    }
  }, [handleDocumentScrollThrottled])
  return isTop
}

export default useDocumentScrollThrottled
