const Reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      return {
        ...state,
        selectedLanguage: action.payload,
        navLinks: state.urlArray[action.payload],
        languages: state.langArray[action.payload].sort((a, b) =>
          a.val > b.val ? 1 : b.val > a.val ? -1 : 0
        ),
      }
    default:
      return state
  }
}
export default Reducer
