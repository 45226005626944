import React from "react"

function Logo(props) {
  return (
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 402.19 54.43"
      {...props}
    >
      <defs>
        <style>
          {".prefix__cls-1{fill:#35459c}.prefix__cls-3{fill:#ee1e3a}"}
        </style>
      </defs>
      <title>{"pixel test"}</title>
      <path
        className="prefix__cls-1"
        d="M0 0h6.4v6.4H0zM0 8.02h6.4v6.4H0zM0 16h6.4v6.43H0zM0 24.02h6.4v6.4H0zM0 32.03h6.4v6.4H0zM0 40.01h6.4v6.4H0zM0 48.03h6.4v6.4H0zM8.02 0h6.4v6.4h-6.4zM8.02 24.02h6.4v6.4h-6.4zM16 0h6.4v6.4H16zM16 24.02h6.4v6.4H16zM24.01 0h6.4v6.4h-6.4zM24.01 24.02h6.4v6.4h-6.4zM31.99 8.02h6.43v6.4h-6.43zM31.99 16h6.43v6.43h-6.43z"
      />
      <path fill="#6abd45" d="M47.99 0h6.4v6.43h-6.4z" />
      <path
        className="prefix__cls-1"
        d="M47.99 16h6.4v6.43h-6.4zM47.99 24.02h6.4v6.4h-6.4zM47.99 32.03h6.4v6.4h-6.4zM47.99 40.01h6.4v6.4h-6.4zM47.99 48.03h6.4v6.4h-6.4zM63.98 16h6.4v6.43h-6.4zM63.98 24.02h6.4v6.4h-6.4zM63.98 32.03h6.4v6.4h-6.4zM63.98 40.01h6.4v6.4h-6.4zM63.98 48.03h6.4v6.4h-6.4zM72 32.03h6.4v6.4H72zM79.98 24.02h6.4v6.4h-6.4zM79.98 40.01h6.4v6.4h-6.4zM88 16h6.4v6.43H88zM88 48.03h6.4v6.4H88zM103.96 24.02h6.4v6.4h-6.4zM103.96 32.03h6.4v6.4h-6.4zM103.96 48.03h6.4v6.4h-6.4zM111.97 16h6.4v6.43h-6.4zM111.97 32.03h6.4v6.4h-6.4zM111.97 48.03h6.4v6.4h-6.4zM119.95 16h6.4v6.43h-6.4zM119.95 32.03h6.4v6.4h-6.4zM119.95 48.03h6.4v6.4h-6.4zM127.97 16h6.4v6.43h-6.4zM127.97 40.01h6.4v6.4h-6.4zM143.93 16h6.4v6.43h-6.4zM143.93 24.02h6.4v6.4h-6.4zM143.93 32.03h6.4v6.4h-6.4zM143.93 40.01h6.4v6.4h-6.4zM151.95 16h6.4v6.43h-6.4zM151.95 32.03h6.4v6.4h-6.4zM151.95 48.03h6.4v6.4h-6.4zM159.93 16h6.4v6.43h-6.4zM159.93 32.03h6.4v6.4h-6.4zM159.93 48.03h6.4v6.4h-6.4zM167.94 16h6.4v6.43h-6.4zM167.94 24.02h6.4v6.4h-6.4zM167.94 32.03h6.4v6.4h-6.4zM167.94 48.03h6.4v6.4h-6.4zM183.9 16h6.4v6.43h-6.4zM183.9 24.02h6.4v6.4h-6.4zM183.9 32.03h6.4v6.4h-6.4zM183.9 40.01h6.4v6.4h-6.4zM183.9 48.03h6.4v6.4h-6.4zM191.92 48.03h6.4v6.4h-6.4zM199.9 48.03h6.4v6.4h-6.4zM207.91 48.03h6.4v6.4h-6.4z"
      />
      <path
        className="prefix__cls-3"
        d="M243.84 0h6.4v6.4h-6.4zM251.86 0h6.4v6.4h-6.4zM259.84 0h6.4v6.4h-6.4zM259.84 8.02h6.4v6.4h-6.4zM259.84 16h6.4v6.43h-6.4zM259.84 24.02h6.4v6.4h-6.4zM259.84 32.03h6.4v6.4h-6.4zM259.84 40.01h6.4v6.4h-6.4zM259.84 48.03h6.4v6.4h-6.4zM267.85 0h6.4v6.4h-6.4zM275.84 0h6.43v6.4h-6.43zM291.83 16h6.4v6.43h-6.4zM291.83 24.02h6.4v6.4h-6.4zM291.83 32.03h6.4v6.4h-6.4zM291.83 40.01h6.4v6.4h-6.4zM299.85 16h6.4v6.43h-6.4zM299.85 32.03h6.4v6.4h-6.4zM299.85 48.03h6.4v6.4h-6.4zM307.83 16h6.4v6.43h-6.4zM307.83 32.03h6.4v6.4h-6.4zM307.83 48.03h6.4v6.4h-6.4zM315.84 16h6.4v6.43h-6.4zM315.84 24.02h6.4v6.4h-6.4zM315.84 32.03h6.4v6.4h-6.4zM315.84 48.03h6.4v6.4h-6.4zM331.8 24.02h6.4v6.4h-6.4zM331.8 32.03h6.4v6.4h-6.4zM331.8 48.03h6.4v6.4h-6.4zM339.82 16h6.4v6.43h-6.4zM339.82 32.03h6.4v6.4h-6.4zM339.82 48.03h6.4v6.4h-6.4zM347.8 16h6.4v6.43h-6.4zM347.8 32.03h6.4v6.4h-6.4zM347.8 48.03h6.4v6.4h-6.4zM355.82 16h6.4v6.43h-6.4zM355.82 40.01h6.4v6.4h-6.4zM371.78 16h6.4v6.43h-6.4zM379.79 0h6.4v6.4h-6.4zM379.79 8.02h6.4v6.4h-6.4zM379.79 16h6.4v6.43h-6.4zM379.79 24.02h6.4v6.4h-6.4zM379.79 32.03h6.4v6.4h-6.4zM379.79 40.01h6.4v6.4h-6.4zM379.79 48.03h6.4v6.4h-6.4zM387.77 16h6.4v6.43h-6.4zM387.77 48.03h6.4v6.4h-6.4zM395.79 16h6.4v6.43h-6.4z"
      />
      <path d="M395.79 48.03h6.4v6.4h-6.4z" />
    </svg>
  )
}

export default Logo
