import React, { memo} from "react"
import { Menu, Transition } from "@headlessui/react"
import { Link } from "gatsby"
import useStore from "../../store/useStore"
import SvgMenuIcon from "../Icons/SvgMenuIcon"


const MenuButton = () => {
  const { navLinks } = useStore()
  return (
    <div className="inline-block ml-3">
      <Menu>
        {({ open }) => (
          <>
            <span>
              <Menu.Button type="button" className="bg-purple-600 focus:bg-purple-700 hover:bg-purple-700 text-white  px-2 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-colors duration-150">
                <SvgMenuIcon
                  className="fill-current max-h-full max-w-full"
                  height={18}
                  width={18}
                />
              </Menu.Button>
            </span>
            <Transition
              show={open}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
              >
                {navLinks.map(nv => (
                  <Menu.Item
                    key={nv.url}
                    as={Link}
                    to={nv.url}
                    className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left hover:bg-gray-100  ease-linear transition-colors duration-150 outline-none focus:outline-none"
                  >
                    {nv.val}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
     
    </div>
  )
}
export default memo(MenuButton)
