// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-global-de-pages-dead-pixel-fix-de-js": () => import("./../../../src/global/de/pages/dead-pixel-fix-de.js" /* webpackChunkName: "component---src-global-de-pages-dead-pixel-fix-de-js" */),
  "component---src-global-de-pages-dead-pixel-test-de-js": () => import("./../../../src/global/de/pages/dead-pixel-test-de.js" /* webpackChunkName: "component---src-global-de-pages-dead-pixel-test-de-js" */),
  "component---src-global-de-pages-pixeltest-js": () => import("./../../../src/global/de/pages/pixeltest.js" /* webpackChunkName: "component---src-global-de-pages-pixeltest-js" */),
  "component---src-global-de-pages-stuck-pixel-fix-de-js": () => import("./../../../src/global/de/pages/stuck-pixel-fix-de.js" /* webpackChunkName: "component---src-global-de-pages-stuck-pixel-fix-de-js" */),
  "component---src-global-en-pages-dead-pixel-fix-js": () => import("./../../../src/global/en/pages/dead-pixel-fix.js" /* webpackChunkName: "component---src-global-en-pages-dead-pixel-fix-js" */),
  "component---src-global-en-pages-dead-pixel-test-js": () => import("./../../../src/global/en/pages/dead-pixel-test.js" /* webpackChunkName: "component---src-global-en-pages-dead-pixel-test-js" */),
  "component---src-global-en-pages-home-js": () => import("./../../../src/global/en/pages/home.js" /* webpackChunkName: "component---src-global-en-pages-home-js" */),
  "component---src-global-en-pages-stuck-pixel-fix-js": () => import("./../../../src/global/en/pages/stuck-pixel-fix.js" /* webpackChunkName: "component---src-global-en-pages-stuck-pixel-fix-js" */),
  "component---src-global-es-pages-correccion-de-pixeles-atascados-js": () => import("./../../../src/global/es/pages/correccion-de-pixeles-atascados.js" /* webpackChunkName: "component---src-global-es-pages-correccion-de-pixeles-atascados-js" */),
  "component---src-global-es-pages-correccion-de-pixeles-muertos-js": () => import("./../../../src/global/es/pages/correccion-de-pixeles-muertos.js" /* webpackChunkName: "component---src-global-es-pages-correccion-de-pixeles-muertos-js" */),
  "component---src-global-es-pages-prueba-de-pixeles-js": () => import("./../../../src/global/es/pages/prueba-de-pixeles.js" /* webpackChunkName: "component---src-global-es-pages-prueba-de-pixeles-js" */),
  "component---src-global-es-pages-prueba-de-pixeles-muertos-js": () => import("./../../../src/global/es/pages/prueba-de-pixeles-muertos.js" /* webpackChunkName: "component---src-global-es-pages-prueba-de-pixeles-muertos-js" */),
  "component---src-global-fr-pages-correction-des-pixels-bloques-js": () => import("./../../../src/global/fr/pages/correction-des-pixels-bloques.js" /* webpackChunkName: "component---src-global-fr-pages-correction-des-pixels-bloques-js" */),
  "component---src-global-fr-pages-correction-des-pixels-morts-js": () => import("./../../../src/global/fr/pages/correction-des-pixels-morts.js" /* webpackChunkName: "component---src-global-fr-pages-correction-des-pixels-morts-js" */),
  "component---src-global-fr-pages-test-de-pixel-js": () => import("./../../../src/global/fr/pages/test-de-pixel.js" /* webpackChunkName: "component---src-global-fr-pages-test-de-pixel-js" */),
  "component---src-global-fr-pages-test-de-pixels-morts-js": () => import("./../../../src/global/fr/pages/test-de-pixels-morts.js" /* webpackChunkName: "component---src-global-fr-pages-test-de-pixels-morts-js" */),
  "component---src-global-it-pages-controlla-dead-pixel-js": () => import("./../../../src/global/it/pages/controlla-dead-pixel.js" /* webpackChunkName: "component---src-global-it-pages-controlla-dead-pixel-js" */),
  "component---src-global-it-pages-controlla-pixel-js": () => import("./../../../src/global/it/pages/controlla-pixel.js" /* webpackChunkName: "component---src-global-it-pages-controlla-pixel-js" */),
  "component---src-global-it-pages-riparazione-pixel-bloccati-js": () => import("./../../../src/global/it/pages/riparazione-pixel-bloccati.js" /* webpackChunkName: "component---src-global-it-pages-riparazione-pixel-bloccati-js" */),
  "component---src-global-it-pages-riparazione-pixel-morti-js": () => import("./../../../src/global/it/pages/riparazione-pixel-morti.js" /* webpackChunkName: "component---src-global-it-pages-riparazione-pixel-morti-js" */),
  "component---src-global-pt-pages-correcao-de-pixel-travado-js": () => import("./../../../src/global/pt/pages/correcao-de-pixel-travado.js" /* webpackChunkName: "component---src-global-pt-pages-correcao-de-pixel-travado-js" */),
  "component---src-global-pt-pages-dead-pixel-repair-js": () => import("./../../../src/global/pt/pages/dead-pixel-repair.js" /* webpackChunkName: "component---src-global-pt-pages-dead-pixel-repair-js" */),
  "component---src-global-pt-pages-verificar-pixel-js": () => import("./../../../src/global/pt/pages/verificar-pixel.js" /* webpackChunkName: "component---src-global-pt-pages-verificar-pixel-js" */),
  "component---src-global-pt-pages-verifique-o-pixel-morto-js": () => import("./../../../src/global/pt/pages/verifique-o-pixel-morto.js" /* webpackChunkName: "component---src-global-pt-pages-verifique-o-pixel-morto-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-olu-piksel-tamiri-js": () => import("./../../../src/pages/olu-piksel-tamiri.js" /* webpackChunkName: "component---src-pages-olu-piksel-tamiri-js" */),
  "component---src-pages-olu-piksel-testi-js": () => import("./../../../src/pages/olu-piksel-testi.js" /* webpackChunkName: "component---src-pages-olu-piksel-testi-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sikismis-piksel-tamiri-js": () => import("./../../../src/pages/sikismis-piksel-tamiri.js" /* webpackChunkName: "component---src-pages-sikismis-piksel-tamiri-js" */),
  "component---src-pages-term-of-usage-js": () => import("./../../../src/pages/term-of-usage.js" /* webpackChunkName: "component---src-pages-term-of-usage-js" */)
}

