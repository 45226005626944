import React from "react"
import Helmet from "react-helmet"
import StoreContext from "../store"
import Navbar from "./Navbar"
import Footer from "./footer"
const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        {/* <script
          data-ad-client="ca-pub-2832277423302323"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script> */}
        <script
          data-ad-client="ca-pub-7229680659964393"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      </Helmet>
      <StoreContext>
        <div className="min-h-screen">
          <Navbar />
          <main
            className="px-3 py-5 mx-auto prose-sm prose mt-14 sm:prose lg:prose-lg xl:prose-xl md:py-10 md:pb-20 md:px-0"
            style={{ minHeight: "calc(100vh - 50px)" }}
          >
            {children}
          </main>
          <Footer />
        </div>
      </StoreContext>
    </>
  )
}
export default Layout
