import { useContext } from "react"
import { navigate } from "gatsby"
import { storeContext } from "./index"
const useStore = () => {
  const [state, dispatch] = useContext(storeContext)
  const changeLanguage = lang => {
    const currentPath =
      typeof window !== "undefined" ? window.location.pathname : ""
    const urlIndex = state.navLinks.findIndex(({ url }) => url === currentPath)
    dispatch({ type: "CHANGE_LANGUAGE", payload: lang })
    if (typeof window !== "undefined") {
      localStorage.setItem("selectedLanguage", lang)
    }
    if (urlIndex !== -1) {
      navigate(state.urlArray[lang][urlIndex].url)
    }
  }
  return {
    changeLanguage,
    selectedLanguage: state.selectedLanguage,
    navLinks: state.navLinks,
    languages: state.languages,
  }
}

export default useStore
